import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import './ModalDialog.css';

/**
 * ModalDialog component
 * @param {boolean} isOpen
 * @param {element} children
 */
const ModalDialog = ({ isOpen, children }) => {
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    }
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isOpen]);

  if (!isOpen) {
    return null;
  }

  return ReactDOM.createPortal(
    <>
      <div className="modal-wrapper" />
      <div className="modal">
        {children}
      </div>
    </>,
    document.getElementById('modal-dialog')
  );
};

ModalDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  children: PropTypes.element.isRequired
};

export default ModalDialog;
