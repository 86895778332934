import { memo } from "react";
import { Box, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";

const Counter = ({ preview, max, current, right, inputKey }) => {
  const theme = useTheme();

  return (
    preview && (
      <Box
        variant="body2"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          cursor: "pointer",
        }}
      >
        <Typography
          variant="body2"
          sx={{
            color: theme.palette.grey[500],
            width: "100%",
            textAlign: right ? "right" : "left",
          }}
          data-testid={`${inputKey}-counter`}
        >
          {Math.min(current, max)} / {max}
        </Typography>
      </Box>
    )
  );
};

Counter.propTypes = {
  right: PropTypes.bool,
  max: PropTypes.number.isRequired,
  current: PropTypes.number.isRequired,
  preview: PropTypes.bool,
  inputKey: PropTypes.string.isRequired,
};

Counter.defaultProps = {
  right: false,
  preview: false,
};

export default memo(Counter);
