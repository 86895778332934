import { memo, useState } from "react";
import PropTypes from "prop-types";
import { Box, TextField } from "@mui/material";
import Counter from "../Counter/Counter";
import { useTheme } from "@mui/material/styles";

const Input = ({
  value,
  maxLength,
  inputKey,
  placeholder,
  updateInputChanges,
  loading,
  preview,
  right,
  error,
  hideCounter,
  updateWebsiteDetails,
}) => {
  const theme = useTheme();
  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = (event) => {
    setIsFocused(true);
    event.target.select();
  };

  const handleBlur = (event) => {
    setIsFocused(false);
    updateWebsiteDetails(event);
  };

  return (
    <Box>
      <TextField
        sx={{
          "& .MuiInputBase-root": {
            color: preview
              ? theme.palette.grey[500]
              : theme.palette.text.primary,
            [theme.breakpoints.between(360, 767)]: {
              height: theme.spacing(5),
            },
          },
          "& .MuiInputBase-input": {
            textAlign: right ? "right" : "left",
          },
        }}
        fullWidth
        value={value}
        placeholder={placeholder}
        onChange={updateInputChanges}
        disabled={loading}
        onFocus={handleFocus}
        onBlur={handleBlur}
        inputProps={{
          maxLength: maxLength,
          id: inputKey,
        }}
        error={Boolean(error)}
        helperText={error}
      />
      {!hideCounter && isFocused && (
        <Counter
          right={right}
          max={maxLength}
          current={value ? value.length : 0}
          preview={preview}
          inputKey={inputKey}
        />
      )}
    </Box>
  );
};

Input.propTypes = {
  value: PropTypes.string.isRequired,
  maxLength: PropTypes.number.isRequired,
  inputKey: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  updateInputChanges: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  preview: PropTypes.bool.isRequired,
  right: PropTypes.bool.isRequired,
  error: PropTypes.string,
  hideCounter: PropTypes.bool,
  updateWebsiteDetails: PropTypes.func.isRequired,
};

Input.defaultProps = {
  error: null,
  hideCounter: false,
};

export default memo(Input);
