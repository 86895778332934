import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import {
  CornerLoader,
  Banner,
  LoginForm,
  PreviewFooter,
  Grid,
} from "../../components";
import {
  getCompanyDetails,
  updateCompanyDetails,
  saveCompanyDetails,
} from "../../store/appStore";
import { DEFAULT_VALUE } from "../../constants/data";
import Header from "../../components/Header/Header";
import GridContainer from "../../components/GridContainer/GridContainer";
import { DEFAULT_DATA } from "../../constants/data";

const ProspectRegistrationPreview = ({ preview }) => {
  const { companyId } = useParams();
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.appKey.loading);
  const isPhoneFieldEnabled = useSelector(
    (state) => state.appKey.isPhoneFieldEnabled
  );
  const isPhoneFieldMandatoryForProspects = useSelector(
    (state) => state.appKey.isPhoneFieldMandatoryForProspects
  );
  const placeHolders = useSelector((state) => state.appKey.placeHolder);
  const isRightToLeftLanguageLayout = useSelector(
    (state) => state.appKey.isRightToLeftLanguageLayout
  );
  const companyWebsiteModel = useSelector(
    (state) => state.appKey.companyWebsiteModel
  );
  const logo = useSelector((state) => state.appKey.logo);
  const defaultCurrency = useSelector((state) => state.appKey.defaultCurrency);

  const [details, setDetails] = useState(companyWebsiteModel);
  const [reset, setReset] = useState(false);

  useEffect(() => {
    if (companyId) {
      dispatch(getCompanyDetails(companyId));
    }
  }, []);

  useEffect(() => {
    setDetails(companyWebsiteModel);
  }, [companyWebsiteModel]);

  const updateInputChanges = (event) => {
    const key = event.target.id;
    const value = event.target.value;
    setDetails((prev) => ({ ...prev, [key]: value }));
  };

  const updateWebsiteDetails = () => {
    dispatch(
      updateCompanyDetails({
        ...details,
        companyId,
        isPhoneFieldMandatoryForProspects,
      })
    );
    setReset(true);
  };

  const saveWebsiteDetails = () => {
    dispatch(saveCompanyDetails({ ...details, companyId }));
  };

  const resetWebsiteDetails = () => {
    const updatedDefaultData = {
      ...DEFAULT_DATA,
      fieldOneStatus: details.fieldOneStatus,
      fieldTwoStatus: details.fieldTwoStatus,
      fieldThreeStatus: details.fieldThreeStatus,
      fieldFourStatus: details.fieldFourStatus,
    };
    setDetails(updatedDefaultData);
    dispatch(
      updateCompanyDetails({
        ...updatedDefaultData,
        logo: undefined,
        fieldOneText:
          updatedDefaultData.fieldOneStatus === 0
            ? undefined
            : updatedDefaultData.fieldOneText,
        fieldTwoText:
          updatedDefaultData.fieldTwoStatus === 0
            ? undefined
            : updatedDefaultData.fieldTwoText,
        fieldThreeText:
          updatedDefaultData.fieldThreeStatus === 0
            ? undefined
            : updatedDefaultData.fieldThreeText,
        fieldFourText:
          updatedDefaultData.fieldFourStatus === 0
            ? undefined
            : updatedDefaultData.fieldFourText,
        companyId,
        isPhoneFieldMandatoryForProspects,
      })
    );
  };

  const getAdjustedPreviewLength = () => {
    const adjustedLength = { ...DEFAULT_VALUE.previewLength };

    if (companyWebsiteModel.fieldOneStatus === 1) {
      adjustedLength.fieldOne = 34;
    }
    if (companyWebsiteModel.fieldTwoStatus === 1) {
      adjustedLength.fieldTwo = 34;
    }
    if (companyWebsiteModel.fieldThreeStatus === 1) {
      adjustedLength.fieldThree = 34;
    }
    if (companyWebsiteModel.fieldFourStatus === 1) {
      adjustedLength.fieldFour = 34;
    }

    return adjustedLength;
  };

  const adjustedPreviewLength = getAdjustedPreviewLength();

  return (
    <>
      {loading && <CornerLoader />}
      <Grid container>
        <Grid xs={12}>
          <Header
            preview={preview}
            saveWebsiteDetails={saveWebsiteDetails}
            reset={reset}
            resetWebsiteDetails={resetWebsiteDetails}
          />
        </Grid>
        <GridContainer
          layoutDirection={isRightToLeftLanguageLayout}
        >
          <Grid
            sm={12}
            md={6}
            lg={7}
            justifyContent={{ xs: "center", md: "end" }}
            sx={{ display: "flex", alignItems: "center" }}
          >
            <Banner
              right={isRightToLeftLanguageLayout}
              bannerMessage={details.bannerMessage}
              updateInputChanges={updateInputChanges}
              updateWebsiteDetails={updateWebsiteDetails}
              preview={preview}
              loading={loading}
            />
          </Grid>
          <Grid
            md={6}
            lg={5}
            display={{ xs: "none", md: "flex" }}
            sx={{ justifyContent: "start", alignItems: "center" }}
          >
            <LoginForm
              preview={preview}
              right={isRightToLeftLanguageLayout}
              isPhoneFieldEnabled={isPhoneFieldEnabled}
              companyWebsiteModel={details}
              updateWebsiteDetails={updateWebsiteDetails}
              updateInputChanges={updateInputChanges}
              loading={loading}
              logo={logo}
              placeholderData={placeHolders}
              length={adjustedPreviewLength}
              defaultCurrency={defaultCurrency}
            />
          </Grid>
        </GridContainer>
        <Grid
          container
          xs={12}
          display={{ xs: "flex", md: "none" }}
          sx={{ justifyContent: "center", alignItems: "center" }}
        >
          <LoginForm
            preview={preview}
            right={isRightToLeftLanguageLayout}
            isPhoneFieldEnabled={isPhoneFieldEnabled}
            companyWebsiteModel={details}
            updateWebsiteDetails={updateWebsiteDetails}
            updateInputChanges={updateInputChanges}
            loading={loading}
            logo={logo}
            placeholderData={placeHolders}
            length={adjustedPreviewLength}
            defaultCurrency={defaultCurrency}
          />
        </Grid>
        <Grid xs={12}>
          <PreviewFooter />
        </Grid>
      </Grid>
    </>
  );
};

ProspectRegistrationPreview.propTypes = {
  preview: PropTypes.bool,
};

export default ProspectRegistrationPreview;
