import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import store from './store/storeConfiguration';
import { Provider } from 'react-redux';
import './index.css';
import MICROSOFT_CLARITY_SCRIPT from './constants/analytics';

if (process.env.MICROSOFT_CLARITY_IS_ENABLED) {
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.innerHTML = MICROSOFT_CLARITY_SCRIPT;
  document.head.appendChild(script);
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
