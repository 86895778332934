import { Box, Button } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Logo } from "hello-design-system";

/**
 * Header component
 */
const Header = ({
  preview,
  saveWebsiteDetails,
  reset,
  resetWebsiteDetails,
}) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        height: theme.spacing(10),
        paddingTop: theme.spacing(1),
        marginLeft: {
          xs: theme.spacing(1),
          sm: theme.spacing(3),
        },
        marginRight: {
          xs: theme.spacing(1),
          sm: theme.spacing(3),
        },
      }}
    >
      <Box>
        <Logo color="primary" height={80} variant="vertical" width={100} />
      </Box>
      {preview && (
        <Box
          sx={{
            display: "flex",
            gap: theme.spacing(1.5),
            alignItems: "center",
          }}
        >
          <Button
            sx={{
              height: theme.spacing(6),
              [theme.breakpoints.down(767)]: {
                size: "medium",
                fontSize: theme.spacing(1.5),
              },
            }}
            variant="outlined"
            disabled={!reset}
            onClick={resetWebsiteDetails}
          >
            Reset
          </Button>
          <Button
            sx={{
              height: theme.spacing(6),
              [theme.breakpoints.down("360")]: {
                size: "medium",
                fontSize: theme.spacing(1.5),
              },
            }}
            onClick={saveWebsiteDetails}
            variant="contained"
          >
            Save and go back
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default Header;
