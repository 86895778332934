import React, { memo } from 'react';

import './CornerLoader.css';

/**
 * CornerLoader component
 */
const CornerLoader = () => {
  return (
    <div className={`corner-loader corner-loader-animate`} data-testid='corner-loader-test-id'>
      <div className="circle-loader" />
    </div>
  );
};

export default memo(CornerLoader);
