import { memo } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { DEFAULT_COUNTRY_CODE, DEFAULT_KEY } from '../../constants/data';
import { useTheme } from "@mui/material/styles";

const CountrySelect = ({ preview, right, updateInputChanges, loading, defaultCurrency }) => {
  const theme = useTheme();
  return (
    <Box className={`code ${preview ? 'input-preview' : 'input-default'}`}>
      <Autocomplete
        id='country-select'
        sx={{
          width: 160,
          "& .MuiInputBase-root": {
            [theme.breakpoints.between(360, 767)]: {
              height: theme.spacing(5),
            },
          },
        }}
        options={DEFAULT_COUNTRY_CODE}
        autoHighlight
        onChange={updateInputChanges}
        defaultValue={defaultCurrency}
        disableClearable
        disabled={preview || loading}
        getOptionLabel={(option) => option.countryName + ' ' + option.countryCode}
        renderOption={(props, option) => (
          <Box component='li' sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
            {option.countryName} ({option.countryCode})
          </Box>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
              autoComplete: 'new-password',
              id: DEFAULT_KEY.countryCode,
              'data-testid': `${DEFAULT_KEY.countryCode}-test`,
            }}
            disabled={preview || loading}
            endAdornment={right && !preview && <span>+{params.InputProps.value}</span>} 
          />
        )}
      />
    </Box>
  );
}

CountrySelect.propTypes = {
  preview: PropTypes.bool,
  right: PropTypes.bool.isRequired,
  updateInputChanges: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  defaultCurrency: PropTypes.object
};

CountrySelect.defaultProps = {
  preview: false,
};

export default memo(CountrySelect);

