import { apiRequestWithDefaultHeader } from '../../helpers/apiRequest/apiRequest';

/**
 * To get Privacy Policy Content
 * @param {string} policyUrl
 * @returns {Promise<object>}
 */
 const getPrivacyPolicyContent = (policyUrl) => {
  return apiRequestWithDefaultHeader('GET', policyUrl)
    .then((response) => {
      return response;
    });
};

export default getPrivacyPolicyContent;
