import { createSlice } from '@reduxjs/toolkit';
import { getApi, postApi } from '../../helpers/apiRequest/apiRequest';
import { addAsteriskValue, appendAsteriskIfStatusIsMandatory, getSubDomain, removeAsterisksIfEndsWithAsterisk } from '../../utils/common.service';
import { gets3Object } from '../../helpers/s3/s3';
import {
  createProspect,
  getIdamToken
} from '../../helpers/apiRequest/contractApiRequest';
import { DEFAULT_COUNTRY_CODE, DEFAULT_VALUE } from '../../constants/data';

export const APP_KEY = 'appKey';

export const initialState = {
  loading: false,
  companyWebsiteModel: {
    firstName: '',
    lastName: '',
    email: '',
    submitButtonText: '',
    bannerMessage: '',
    phoneNumber: '',
    title: '',
    fieldOneStatus: 0,
    fieldOneText: '',
    fieldTwoStatus: 0,
    fieldTwoText: '',
    fieldThreeStatus: 0,
    fieldThreeText: '',
    fieldFourStatus: 0,
    fieldFourText: '',
  },
  placeHolder: {
    FirstName: null,
    LastName: null,
    Email: null,
    SubmitButtonText: null,
    BannerMessage: null,
    PhoneNumber: null,
    Title: null,
    FieldOneText: null,
    FieldTwoText: null,
    FieldThreeText: null,
    FieldFourText: null,
  },
  logo: null,
  companyId: null,
  centerReferenceId: null,
  isPhoneFieldEnabled: false,
  isPhoneFieldMandatoryForProspects: false,
  isRightToLeftLanguageLayout: false,
  showTestResult: false,
  policyStatus: false,
  prospectId: null,
  openPolicyModal: false,
  defaultCurrency: null,
  error: null,
};

export const appSlice = createSlice({
  name: APP_KEY,
  initialState,
  reducers: {
    setDetails: (state, action) => {
      state.companyWebsiteModel = action.payload.companyWebsiteModel;
      state.isPhoneFieldEnabled = action.payload.isPhoneFieldEnabled;
      state.isPhoneFieldMandatoryForProspects = action.payload.isPhoneFieldMandatoryForProspects;
      if (state.isPhoneFieldMandatoryForProspects) {
        state.companyWebsiteModel.phoneNumber = `${state.companyWebsiteModel.phoneNumber}*`
      }
      state.companyWebsiteModel.fieldOneText = appendAsteriskIfStatusIsMandatory(state.companyWebsiteModel.fieldOneStatus, state.companyWebsiteModel.fieldOneText);
      state.companyWebsiteModel.fieldTwoText = appendAsteriskIfStatusIsMandatory(state.companyWebsiteModel.fieldTwoStatus, state.companyWebsiteModel.fieldTwoText);
      state.companyWebsiteModel.fieldThreeText = appendAsteriskIfStatusIsMandatory(state.companyWebsiteModel.fieldThreeStatus, state.companyWebsiteModel.fieldThreeText);
      state.companyWebsiteModel.fieldFourText = appendAsteriskIfStatusIsMandatory(state.companyWebsiteModel.fieldFourStatus, state.companyWebsiteModel.fieldFourText);
      state.isRightToLeftLanguageLayout = action.payload.isRightToLeftLanguageLayout;
      state.logo = action.payload.logoBlobUrl;
      const currency = DEFAULT_COUNTRY_CODE.find(e => e.countryName === action.payload.territoryName);
      state.defaultCurrency = currency
    },
    setCompanyId: (state, action) => {
      state.companyId = action.payload;
    },
    updateWebsiteInfo: (state, action) => {
      state.companyWebsiteModel = action.payload;
    },
    updateLoading: (state, action) => {
      state.loading = action.payload;
    },
    updatePlaceHolderDetails: (state, action) => {
      state.isPhoneFieldEnabled = action.payload.IsPhoneFieldEnabled;
      state.isPhoneFieldMandatoryForProspects = action.payload.IsPhoneFieldMandatoryForProspects
      state.isRightToLeftLanguageLayout = action.payload.IsRightToLeftLanguageLayout;
      state.logo = action.payload.CompanyLogo;
      state.placeHolder = action.payload.PlaceHolders;
      if (action.payload.IsPhoneFieldMandatoryForProspects) {
        state.placeHolder.PhoneNumber = `${state.placeHolder.PhoneNumber} *`;
      }
      state.placeHolder.FieldOneText = appendAsteriskIfStatusIsMandatory(action.payload.PlaceHolders.FieldOneStatus, state.placeHolder.FieldOneText);
      state.placeHolder.FieldTwoText = appendAsteriskIfStatusIsMandatory(action.payload.PlaceHolders.FieldTwoStatus, state.placeHolder.FieldTwoText);
      state.placeHolder.FieldThreeText = appendAsteriskIfStatusIsMandatory(action.payload.PlaceHolders.FieldThreeStatus, state.placeHolder.FieldThreeText);
      state.placeHolder.FieldFourText = appendAsteriskIfStatusIsMandatory(action.payload.PlaceHolders.FieldFourStatus, state.placeHolder.FieldFourText);
      state.companyId = action.payload.CompanyId;
      state.centerReferenceId = action.payload.CenterReferenceId;
      state.showTestResult = action.payload.ShowResults;
      state.defaultCurrency = DEFAULT_COUNTRY_CODE.find((option) => option.countryName === action.payload.TerritoryName);
      
    },
    updatePolicyStatus: (state, action) => {
      state.policyStatus = action.payload;
    },
    setProspectId: (state, action) => {
      state.prospectId = action.payload;
    },
    updateError: (state, action) => {
      state.error = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setCompanyId,
  setDetails,
  updateLoading,
  updateWebsiteInfo,
  updatePlaceHolderDetails,
  updatePolicyStatus,
  setProspectId,
  updateError,
} = appSlice.actions;

export const getCompanyDetails = (companyId) => {
  return async (dispatch) => {
    try {
      dispatch(updateLoading(true));
      const {
        companyWebsiteModel,
        isPhoneFieldEnabled,
        isRightToLeftLanguageLayout,
        isPhoneFieldMandatoryForProspects,
        logoBlobUrl,
        territoryName,
      } = await getApi(`Companies/${companyId}`);
      dispatch(
        setDetails({
          companyWebsiteModel,
          isPhoneFieldEnabled,
          isRightToLeftLanguageLayout,
          isPhoneFieldMandatoryForProspects,
          logoBlobUrl,
          territoryName,
        })
      );
      dispatch(updateLoading(false));
    } catch (err) {
      dispatch(updateLoading(false));
      if (err.response.status) {
        window.location.replace(`${process.env.REACT_APP_NSE_URL}`);
      }
    }
  };
};

export const updateCompanyDetails = (webSiteDetails) => {
  return async (dispatch) => {
    try {
      const requiredFields = DEFAULT_VALUE.requiredFields;
      if (webSiteDetails.isPhoneFieldMandatoryForProspects) {
        requiredFields.push('phoneNumber');
      }
      if (webSiteDetails.fieldOneStatus === 1) {
        requiredFields.push('fieldOneText');
      }
      if (webSiteDetails.fieldTwoStatus === 1) {
        requiredFields.push('fieldTwoText');
      }
      if (webSiteDetails.fieldThreeStatus === 1) {
        requiredFields.push('fieldThreeText');
      }
      if (webSiteDetails.fieldFourStatus === 1) {
        requiredFields.push('fieldFourText');
      }
      const companyModelValue = addAsteriskValue(webSiteDetails, requiredFields);
      dispatch(updateLoading(true));
      await postApi('Companies/website/details', {
        ...companyModelValue,
        phoneNumber: removeAsterisksIfEndsWithAsterisk(companyModelValue.phoneNumber),
        fieldOneText: removeAsterisksIfEndsWithAsterisk(companyModelValue.fieldOneText),
        fieldTwoText: removeAsterisksIfEndsWithAsterisk(companyModelValue.fieldTwoText),
        fieldThreeText: removeAsterisksIfEndsWithAsterisk(companyModelValue.fieldThreeText),
        fieldFourText: removeAsterisksIfEndsWithAsterisk(companyModelValue.fieldFourText)
      });
      dispatch(updateWebsiteInfo(companyModelValue));
      dispatch(updateLoading(false));
    } catch (err) {
      dispatch(updateLoading(false));
      window.location.replace(`${process.env.REACT_APP_NSE_URL}`);
    }
  };
};

export const saveCompanyDetails = (webSiteDetails) => {
  return async (dispatch) => {
    try {
      const requiredFields = DEFAULT_VALUE.requiredFields;
      const companyModelValue = addAsteriskValue(webSiteDetails, requiredFields);
      dispatch(updateLoading(true));
      await postApi('Companies/website/details', {
        ...companyModelValue,
        phoneNumber: removeAsterisksIfEndsWithAsterisk(companyModelValue.phoneNumber),
        fieldOneText: removeAsterisksIfEndsWithAsterisk(companyModelValue.fieldOneText),
        fieldTwoText: removeAsterisksIfEndsWithAsterisk(companyModelValue.fieldTwoText),
        fieldThreeText: removeAsterisksIfEndsWithAsterisk(companyModelValue.fieldThreeText),
        fieldFourText: removeAsterisksIfEndsWithAsterisk(companyModelValue.fieldFourText)
      });
      dispatch(updateLoading(false));
      window.close()
    } catch (err) {
      dispatch(updateLoading(false));
      window.location.replace(`${process.env.REACT_APP_NSE_URL}`);
    }
  };
};

export const fetchSiteDetails = () => {
  return async (dispatch) => {
    try {
      const companyCode = getSubDomain();
      if (companyCode) {
        dispatch(updateLoading(true));
        const response = await gets3Object(companyCode);
        dispatch(updatePlaceHolderDetails(response));
        dispatch(updateLoading(false));
      }
    } catch (err) {
      dispatch(updateLoading(false));
    }
  };
};

export const loginProspect = (data) => {
  return async (dispatch) => {
    try {
      dispatch(updateLoading(true));
      const createProspectRes = await createProspect(data);
      dispatch(setProspectId(createProspectRes.id));
      dispatch(updateLoading(true));
      const getToken = await getIdamToken(data.email);
      if (!getToken?.data?.access_token) {
        dispatch(updateLoading(false));
        return;
      }
      dispatch(updateLoading(false));
      const prospectApiUrl = process.env.REACT_APP_NSE_URL;
      const placementLoginUrl = `${prospectApiUrl}placementLogin/${getToken.data.access_token.replace(/\./g, ',')}`;
      window.location.replace(placementLoginUrl);
    } catch (err) {
      const errMessage = err?.response?.status === 400 ? 'User already exists' : 'Something went wrong';
      dispatch(updateError(errMessage));
      setTimeout(() => {
        dispatch(updateError(null))
      }, 5000)
      dispatch(updateLoading(false));
    }
  };
};

export const acceptPolicy = (prospectId, data) => {
  return async (dispatch) => {
    try {
      dispatch(updateLoading(true));
      dispatch(updatePolicyStatus(true));
      const getToken = await getIdamToken(data);
      if (!getToken?.data?.access_token) {
        dispatch(updateLoading(false));
        return
      }
      const prospectApiUrl = process.env.REACT_APP_NSE_URL;
      dispatch(updateLoading(false));
      const placementLoginUrl = `${prospectApiUrl}placementLogin/${getToken.data.access_token.replace(/\./g, ',')}`;
      window.location.replace(placementLoginUrl);
    } catch (err) {
      dispatch(updateLoading(false));
    }
  };
};

export default appSlice.reducer;
