import { styled, Box } from '@mui/material';

const StyledBannerContainer = styled(Box)(({ theme }) => ({
  [theme.breakpoints.between(360, 767)]: {
    margin: `${theme.spacing(7)} ${theme.spacing(2)} ${theme.spacing(2.5)} ${theme.spacing(0)}`,
    minWidth: theme.spacing(39),
    maxWidth: theme.spacing(58),
    width: theme.spacing(39),
  },
  [theme.breakpoints.between(768, 1023)]: {
    margin: `${theme.spacing(7)} ${theme.spacing(2)} ${theme.spacing(2.5)} ${theme.spacing(3)}`,
    minWidth: theme.spacing(41),
    maxWidth: theme.spacing(81),
    width: theme.spacing(64),
  },
  [theme.breakpoints.between(1024, 1279)]: {
    width: theme.spacing(62),
  },
  [theme.breakpoints.between(1280, 1439)]: {
    width: theme.spacing(79),
  },
  [theme.breakpoints.up(1440)]: {
    width: theme.spacing(81),
  },
  [theme.breakpoints.up(1920)]: {
    width: theme.spacing(89),
  },
}));

export default StyledBannerContainer;
