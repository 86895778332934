import { memo } from "react";
import Policy from "../Policy/Policy";

function PreviewFooter() {
  return (
    <Policy />
  );
}

export default memo(PreviewFooter);
