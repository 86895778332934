import { GetObjectCommand, S3Client } from "@aws-sdk/client-s3";

const client = new S3Client({
  credentials: {
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
  },
  region: process.env.REACT_APP_AWS_REGION
})

export const gets3Object = async (fileName) => {
  const command = new GetObjectCommand({
    Bucket: process.env.REACT_APP_AWS_S3_BUCKET,
    Key: `website-details/${fileName}.json`,
  });

  try {
    const response = await client.send(command);
    const data = await response.Body.transformToString();
    return JSON.parse(data);
  } catch (err) {
    throw err;
  }
};
