import React, { useState, useEffect } from 'react';
import getPrivacyPolicyContent from '../../../../services/PoliciesService/PoliciesService';

// Importing components
import DropDown from '../../../DropDown/DropDown';

/**
 * PrivacyPolicies component
 */
const PrivacyPolicies = () => {
  const [languages, setLanguages] = useState([]);
  const [contents, setContents] = useState('');
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const privacyURL = `${process.env.REACT_APP_ASSET_URL}privacy-multilanguage`;

  /**
   * Function to set the selected language details
   * @param {object} language
   * @param {array} languageList
   */
  const setLanguageDetails = (language, languageList = languages) => {
    setLanguages(languageList.map((eachLanguage) => {
      eachLanguage.isDefault = (eachLanguage.language_code === language.language_code);

      return eachLanguage;
    }));
    setSelectedLanguage(language);
    const dropDownElement = document.querySelector('.dropdown');
    dropDownElement && dropDownElement.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    getPrivacyPolicyContent(`${privacyURL}/pp_languages.json`,)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        const defaultLanguage = data.language_contents.find((language) => {
          return language.language_code === 'en';
        });
        setLanguageDetails(defaultLanguage, data.language_contents);
      });
  }, []);

  useEffect(() => {
    if (languages.length) {
      getPrivacyPolicyContent(`${privacyURL}${selectedLanguage.web_pp_url}`)
        .then((response) => {
          return response.text();
        })
        .then((templateContent) => {
          setContents(templateContent);
        });
    }
  }, [selectedLanguage]);

  return languages.length > 0 && contents && (
    <>
      <DropDown
        selectedItem={selectedLanguage && selectedLanguage.language_name}
        items={languages}
        onClickHandler={setLanguageDetails}
      />
      <div
        className={`typo-default-font-size language-font-${selectedLanguage.language_code}`}
        dangerouslySetInnerHTML={{ __html: contents }}
      />
    </>
  );
};

export default PrivacyPolicies;
