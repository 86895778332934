import { memo, useState } from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { Button, Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { DEFAULT_VALUE, DEFAULT_KEY } from "../../constants/data";
import { Counter, Input } from "../index";
import CountrySelect from "../CountryCode/CountyCode";
import StyledLoginFormContainer from "./StyledLoginFormContainer";

const LoginForm = ({
  preview,
  right,
  isPhoneFieldEnabled,
  companyWebsiteModel,
  updateWebsiteDetails,
  updateInputChanges,
  loading,
  logo,
  placeholderData,
  length,
  errorData,
  onSubmit,
  disabled,
  defaultCurrency,
}) => {
  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = (event) => {
    setIsFocused(true);
    event.target.select();
  };

  const handleBlur = (event) => {
    setIsFocused(false);
    updateWebsiteDetails(event);
  };

  const commonInputProps = {
    preview,
    right,
    updateInputChanges,
    loading,
    updateWebsiteDetails,
  };

  const theme = useTheme();

  const fieldOneEnabled =
    [1, 2].includes(companyWebsiteModel.fieldOneStatus) ||
    [1, 2].includes(placeholderData.FieldOneStatus);

  const fieldTwoEnabled =
    [1, 2].includes(companyWebsiteModel.fieldTwoStatus) ||
    [1, 2].includes(placeholderData.FieldTwoStatus);

  const fieldThreeEnabled =
    [1, 2].includes(companyWebsiteModel.fieldThreeStatus) ||
    [1, 2].includes(placeholderData.FieldThreeStatus);

  const fieldFourEnabled =
    [1, 2].includes(companyWebsiteModel.fieldFourStatus) ||
    [1, 2].includes(placeholderData.FieldFourStatus);

  return (
    <StyledLoginFormContainer>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          borderRadius: theme.spacing(1),
          backgroundColor: theme.palette.common.white,
          gap: theme.spacing(0.5),
          padding: theme.spacing(4),
          [theme.breakpoints.down(767)]: {
            padding: theme.spacing(2),
          },
          width: "100%",
        }}
      >
        {/* Company logo */}
        {logo && (
          <Box
            data-testid="company-logo"
            sx={{
              maxWidth: theme.spacing(20),
              height: theme.spacing(16),
              img: { objectFit: "contain" },
              float: right ? "right" : "left",
            }}
          >
            <img style={{ width: "100%" }} src={logo} alt="company-logo" />
          </Box>
        )}
        {/* Form header text*/}
        <Box className={`test-form ${right ? "right-form" : ""}`}>
          {preview ? (
            <>
              <TextField
                sx={{
                  "& .MuiInputBase-input": {
                    fontWeight: 700,
                    marginTop: theme.spacing(0),
                    [theme.breakpoints.between(360, 767)]: {
                      height: theme.spacing(5),
                    }
                  },
                }}
                name={DEFAULT_VALUE.form.title.name}
                fullWidth
                id={DEFAULT_VALUE.form.title.id}
                data-testid="title-input"
                value={companyWebsiteModel.title}
                placeholder={placeholderData.Title}
                onFocus={handleFocus}
                onChange={updateInputChanges}
                disabled={loading}
                inputProps={{
                  maxLength: DEFAULT_VALUE.form.title.maxLength,
                  id: DEFAULT_KEY.title,
                }}
                onBlur={handleBlur}
              />
              {isFocused && (
                <Counter
                  right={right}
                  max={DEFAULT_VALUE.form.title.maxLength}
                  current={companyWebsiteModel.title.length}
                  preview={preview}
                  inputKey={DEFAULT_KEY.title}
                />
              )}
            </>
          ) : (
            <Typography variant="h6" data-testid="title-message">
              {placeholderData.Title}
            </Typography>
          )}
        </Box>

        {/* First Name Input  */}
        <Input
          {...{
            ...commonInputProps,
            value: companyWebsiteModel.firstName,
            maxLength: length.firstName,
            inputKey: DEFAULT_KEY.firstName,
            placeholder: placeholderData.FirstName,
            error: errorData.firstName,
          }}
        />

        {/* LastName input  */}
        <Input
          {...{
            ...commonInputProps,
            value: companyWebsiteModel.lastName,
            maxLength: length.lastName,
            inputKey: DEFAULT_KEY.lastName,
            placeholder: placeholderData.LastName,
            error: errorData.lastName,
          }}
        />

        {/* Phone input  */}
        {isPhoneFieldEnabled && (
          <Box
            className={`code-number ${right ? "code-number-right" : ""}`}
            sx={{
              display: "flex",
              gap: theme.spacing(1),
              alignItems: "baseline",
            }}
          >
            <CountrySelect
              {...{
                preview,
                right,
                updateInputChanges,
                loading,
                defaultCurrency,
              }}
            />
            <Box sx={{ width: "100%" }}>
              <Input
                {...{
                  ...commonInputProps,
                  value: companyWebsiteModel.phoneNumber,
                  maxLength: length.phoneNumber,
                  inputKey: DEFAULT_KEY.phoneNumber,
                  placeholder: placeholderData.PhoneNumber,
                  error: errorData.phoneNumber,
                }}
              />
            </Box>
          </Box>
        )}

        {/* Email Address Input  */}
        <Input
          {...{
            ...commonInputProps,
            value: companyWebsiteModel.email,
            maxLength: length.email,
            inputKey: DEFAULT_KEY.email,
            placeholder: placeholderData.Email,
            error: errorData.email,
          }}
        />

        {/* Field1 input  */}
        {fieldOneEnabled && (
          <Input
            {...{
              ...commonInputProps,
              value: companyWebsiteModel.fieldOneText,
              maxLength: length.fieldOne,
              inputKey: DEFAULT_KEY.fieldOneText,
              placeholder: placeholderData.FieldOneText,
              error: errorData.fieldOneText,
            }}
          />
        )}

        {/* Field2 input  */}
        {fieldTwoEnabled && (
          <Input
            {...{
              ...commonInputProps,
              value: companyWebsiteModel.fieldTwoText,
              maxLength: length.fieldTwo,
              inputKey: DEFAULT_KEY.fieldTwoText,
              placeholder: placeholderData.FieldTwoText,
              error: errorData.fieldTwoStatus,
            }}
          />
        )}

        {/* Field3 input  */}
        {fieldThreeEnabled && (
          <Input
            {...{
              ...commonInputProps,
              value: companyWebsiteModel.fieldThreeText,
              maxLength: length.fieldThree,
              inputKey: DEFAULT_KEY.fieldThreeText,
              placeholder: placeholderData.FieldThreeText,
              error: errorData.fieldThreeStatus,
            }}
          />
        )}

        {/* Field4 input  */}
        {fieldFourEnabled && (
          <Input
            {...{
              ...commonInputProps,
              value: companyWebsiteModel.fieldFourText,
              maxLength: length.fieldFour,
              inputKey: DEFAULT_KEY.fieldFourText,
              placeholder: placeholderData.FieldFourText,
              error: errorData.fieldFourStatus,
            }}
          />
        )}

        {/* Send Button  */}
        {preview ? (
          <Grid item xs={16} sx={{ marginTop: theme.spacing(4) }}>
            <TextField
              sx={{
                backgroundColor: theme.palette.primary.main,
                "& .MuiInputBase-input": {
                  textAlign: "center",
                  fontWeight: 700,
                },
                "& .MuiInputBase-root": {
                  color: theme.palette.common.white,
                  marginTop: 0,
                  [theme.breakpoints.between(360, 767)]: {
                    height: theme.spacing(4.5),
                  }
                },
              }}
              name={DEFAULT_VALUE.form.submit.name}
              fullWidth
              data-testid="submit-btn-input"
              id={DEFAULT_VALUE.form.submit.id}
              value={companyWebsiteModel.submitButtonText}
              onFocus={handleFocus}
              onChange={updateInputChanges}
              disabled={loading}
              onBlur={handleBlur}
              placeholder={placeholderData.SubmitButtonText}
              inputProps={{
                maxLength: DEFAULT_VALUE.form.submit.maxLength,
              }}
            />
            {isFocused && (
              <Counter
                right={right}
                max={DEFAULT_VALUE.form.submit.maxLength}
                current={companyWebsiteModel.submitButtonText.length}
                preview={preview}
                inputKey={DEFAULT_KEY.submitButton}
              />
            )}
          </Grid>
        ) : (
          <Button
            variant="contained"
            size={theme.breakpoints.between(360, 767) ? 'medium' : 'large'}
            color="primary"
            id="loginSubmitBtn"
            data-testid="submit-btn"
            sx={{
              marginTop: theme.spacing(3),
            }}
            onClick={onSubmit}
            disabled={disabled || loading}
          >
            {placeholderData.SubmitButtonText}
          </Button>
        )}
      </Box>
    </StyledLoginFormContainer>
  );
};

LoginForm.propTypes = {
  preview: PropTypes.bool,
  right: PropTypes.bool.isRequired,
  isPhoneFieldEnabled: PropTypes.bool.isRequired,
  companyWebsiteModel: PropTypes.object.isRequired,
  updateWebsiteDetails: PropTypes.func.isRequired,
  updateInputChanges: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  logo: PropTypes.string,
  errorData: PropTypes.object,
  length: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  defaultCurrency: PropTypes.object.isRequired,
};

LoginForm.defaultProps = {
  preview: false,
  errorData: {
    firstName: null,
    lastName: null,
    email: null,
    phoneNumber: null,
    disabled: true,
  },
};

export default memo(LoginForm);
