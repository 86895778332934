import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ModalDialog from '../../ModalDialog/ModalDialog';

import './PoliciesModal.css';

/**
 * PoliciesModal component
 * @param {string} title
 * @param {boolean} isOpen
 * @param {Function} closeContentHandler
 * @param {element} children
 */
const PoliciesModal = ({
  title, isOpen, closeContentHandler, children
}) => {
  const [openModal, setOpenModal] = useState(null);

  useEffect(() => {
    setOpenModal(isOpen);
  }, [isOpen]);

  /**
   * To close Modal
   */
  const closeHandler = () => {
    closeContentHandler();
    setOpenModal(false);
  };

  return (
    <>
      {openModal
        && (
        <ModalDialog isOpen={isOpen}>
          <div className="footer-policies-modal">
            <div className="modal-header">
              <h3 className="modal-title">{title}</h3>
              <button
                className="close-modal"
                type="button"
                onClick={closeHandler}
              >
                <i className="fa fa-times-thin fa-2x" aria-hidden="true" />
              </button>
            </div>
            <div className="modal-body">
              {children}
            </div>
          </div>
        </ModalDialog>
        )}
    </>
  );
};

PoliciesModal.propTypes = {
  title: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  closeContentHandler: PropTypes.func.isRequired,
  children: PropTypes.element.isRequired
};

export default PoliciesModal;
