import { styled, Box } from "@mui/material";

const StyledLoginFormContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  margin: theme.spacing(4),
  [theme.breakpoints.between(360, 767)]: {
    margin: theme.spacing(2),
    flexDirection: "column",
    alignItems: "flex-start",
    minWidth: theme.spacing(41),
    maxWidth: theme.spacing(66),
    width: theme.spacing(64),
    height: "auto",
  },
  [theme.breakpoints.between(768, 1023)]: {
    flexDirection: "column",
    alignItems: "flex-start",
    minWidth: theme.spacing(41),
    maxWidth: theme.spacing(81),
    width: theme.spacing(64),
    height: "auto",
  },
  [theme.breakpoints.between(1024, 1279)]: {
    display: "flex",
    height: "auto",
    flexDirection: "column",
    alignItems: "center",
    gap: theme.spacing(3),
    margin: theme.spacing(2),
    width: theme.spacing(50),
  },
  [theme.breakpoints.between(1280, 1439)]: {
    display: "flex",
    width: theme.spacing(56),
    height: "auto",
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(6),
  },
  [theme.breakpoints.up(1440)]: {
    width: theme.spacing(64),
    height: "auto",
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(6),
  },
  [theme.breakpoints.up(1920)]: {
    width: theme.spacing(64),
    height: "auto",
  },
}));

export default StyledLoginFormContainer;
