export const DEFAULT_VALUE = {
  bannerMessage: {
    name: 'bannerMessage',
    id: 'bannerMessage',
    maxLength: 150,
  },
  form: {
    title: {
      name: 'title',
      id: 'title',
      maxLength: 35,
    },
    firstName: {
      name: 'firstName',
      id: 'firstName',
      maxLength: 30,
    },
    lastName: {
      name: 'lastName',
      id: 'lastName',
      maxLength: 30,
    },
    phoneNumber: {
      name: 'phone',
      id: 'phone',
      maxLength: 10,
    },
    email: {
      name: 'email',
      id: 'email',
      maxLength: 24,
    },
    fieldOneText: {
      name: 'fieldOneText',
      id: 'fieldOneText',
      maxLength: 35,
    },
    fieldTwoText: {
      name: 'fieldTwoText',
      id: 'fieldTwoText',
      maxLength: 35,
    },
    fieldThreeText: {
      name: 'fieldThreeText',
      id: 'fieldThreeText',
      maxLength: 35,
    },
    fieldFourText: {
      name: 'fieldFourText',
      id: 'fieldFourText',
      maxLength: 35,
    },
    submit: {
      name: 'submitButtonText',
      id: 'submitButtonText',
      maxLength: 10,
    },
  },
  previewLength: {
    firstName: 29,
    lastName: 29,
    phoneNumber: 12,
    email: 23,
    fieldOne: 35,
    fieldTwo: 35,
    fieldThree: 35,
    fieldFour: 35,
  },
  length: {
    firstName: 30,
    lastName: 30,
    phoneNumber: 12,
    email: 172,
  },
  requiredFields: ['firstName', 'lastName', 'email'],
}

export const DEFAULT_DATA = {
  firstName: 'First Name *',
  lastName: 'Last Name *',
  email: 'Email Address *',
  submitButtonText: 'Send',
  bannerMessage:
    "Welcome to your Wall Street English Course. Let's discover your start level.",
  phoneNumber: 'Phone',
  title: 'Complete the form to start the test',
  logo: 'https://b2b-company-wse-files.s3.eu-west-1.amazonaws.com/b6f437ff-5bda-4b0b-9851-e946f52bb0e0/tcs.png',
  fieldOneText: 'Field 1',
  fieldTwoText: 'Field 2',
  fieldThreeText: 'Field 3',
  fieldFourText: 'Field 4',
}

export const DEFAULT_KEY = {
  firstName: 'firstName',
  lastName: 'lastName',
  phoneNumber: 'phoneNumber',
  email: 'email',
  title: 'title',
  submitButton: 'submit',
  countryCode: 'countryCode',
  fieldOneText: 'fieldOneText',
  fieldTwoText: 'fieldTwoText',
  fieldThreeText: 'fieldThreeText',
  fieldFourText: 'fieldFourText',
}

export const DEFAULT_COUNTRY_CODE = [
  {
    countryId: 'c69',
    countryName: 'Algeria',
    RegEx: {},
    minLength: 10,
    maxLength: 10,
    countryCode: '+213',
    numberFormat: '692345678',
  },
  {
    countryId: 'c40',
    countryName: 'Angola',
    RegEx: {},
    minLength: 9,
    maxLength: 9,
    countryCode: '+244',
    numberFormat: '923123456',
  },
  {
    countryId: 'c33',
    countryName: 'Argentina',
    RegEx: {},
    minLength: 10,
    maxLength: 11,
    countryCode: '+54',
    numberFormat: '91123456789',
  },
  {
    countryId: 'c44',
    countryName: 'Austria',
    RegEx: {},
    minLength: 7,
    maxLength: 13,
    countryCode: '+43',
    numberFormat: '664123456',
  },
  {
    countryId: 'c45',
    countryName: 'Bahrain',
    RegEx: {},
    minLength: 8,
    maxLength: 8,
    countryCode: '+973',
    numberFormat: '36001236',
  },
  {
    countryId: 'c73',
    countryName: 'Belgium',
    RegEx: {},
    minLength: 10,
    maxLength: 10,
    countryCode: '+32',
    numberFormat: '9453456789',
  },
  {
    countryId: 'c54',
    countryName: 'Bolivia',
    RegEx: {},
    minLength: 8,
    maxLength: 8,
    countryCode: '+591',
    numberFormat: '71234567',
  },
  {
    countryId: 'c24',
    countryName: 'Brazil',
    RegEx: {},
    minLength: 10,
    maxLength: 11,
    countryCode: '+55',
    numberFormat: '11961234567',
  },
  {
    countryId: 'c65',
    countryName: 'Cambodia',
    RegEx: {},
    minLength: 8,
    maxLength: 9,
    countryCode: '+855',
    numberFormat: '91234567',
  },
  {
    countryId: 'c30',
    countryName: 'Chile',
    RegEx: {},
    minLength: 9,
    maxLength: 9,
    countryCode: '+56',
    numberFormat: '961234567',
  },
  {
    countryId: 'c02',
    countryName: 'China',
    RegEx: {},
    minLength: 5,
    maxLength: 11,
    countryCode: '+86',
    numberFormat: '13123456789',
  },
  {
    countryId: 'c31',
    countryName: 'Colombia',
    RegEx: {},
    minLength: 10,
    maxLength: 10,
    countryCode: '+57',
    numberFormat: '3211234567',
  },
  {
    countryId: 'c19',
    countryName: 'Costa Rica',
    RegEx: {},
    minLength: 8,
    maxLength: 9,
    countryCode: '+506',
    numberFormat: '83123456',
  },
  {
    countryId: 'c46',
    countryName: 'Croatia',
    RegEx: {},
    minLength: 8,
    maxLength: 11,
    countryCode: '+385',
    numberFormat: '9123456789',
  },
  {
    countryId: 'c55',
    countryName: 'Cuba',
    RegEx: {},
    minLength: 8,
    maxLength: 8,
    countryCode: '+53',
    numberFormat: '51234567',
  },
  {
    countryId: 'c16',
    countryName: 'Czech Republic',
    RegEx: {},
    minLength: 9,
    maxLength: 9,
    countryCode: '+420',
    numberFormat: '601123456',
  },
  {
    countryId: 'c47',
    countryName: 'Denmark',
    RegEx: {},
    minLength: 8,
    maxLength: 8,
    countryCode: '+45',
    numberFormat: '32123456',
  },
  {
    countryId: 'c17',
    countryName: 'Dominican Republic',
    RegEx: {},
    minLength: 10,
    maxLength: 10,
    countryCode: '+1 ',
    numberFormat: '8092345678',
  },
  {
    countryId: 'c13',
    countryName: 'Ecuador',
    RegEx: {},
    minLength: 9,
    maxLength: 9,
    countryCode: '+593',
    numberFormat: '991234567',
  },
  {
    countryId: 'c61',
    countryName: 'Egypt',
    RegEx: {},
    minLength: 10,
    maxLength: 10,
    countryCode: '+20',
    numberFormat: '1001234567',
  },
  {
    countryId: 'c56',
    countryName: 'El Salvador',
    RegEx: {},
    minLength: 8,
    maxLength: 8,
    countryCode: '+503',
    numberFormat: '70123456',
  },
  {
    countryId: 'c03',
    countryName: 'France',
    RegEx: {},
    minLength: 10,
    maxLength: 10,
    countryCode: '+33',
    numberFormat: '0612345678',
  },
  {
    countryId: 'c48',
    countryName: 'Georgia',
    RegEx: {},
    minLength: 9,
    maxLength: 9,
    countryCode: '+995',
    numberFormat: '555123456',
  },
  {
    countryId: 'c35',
    countryName: 'Germany',
    RegEx: {},
    minLength: 11,
    maxLength: 11,
    countryCode: '+49',
    numberFormat: '15123456789',
  },
  {
    countryId: 'c23',
    countryName: 'Guatemala',
    RegEx: {},
    minLength: 8,
    maxLength: 8,
    countryCode: '502',
    numberFormat: '51234567',
  },
  {
    countryId: 'c57',
    countryName: 'Honduras',
    RegEx: {},
    minLength: 8,
    maxLength: 8,
    countryCode: '+504',
    numberFormat: '91234567',
  },
  {
    countryId: 'c36',
    countryName: 'Hong Kong',
    RegEx: {},
    minLength: 8,
    maxLength: 8,
    countryCode: '+852',
    numberFormat: '51234567',
  },
  {
    countryId: 'c49',
    countryName: 'Hungary',
    RegEx: {},
    minLength: 9,
    maxLength: 9,
    countryCode: '+36',
    numberFormat: '201234567',
  },
  {
    countryId: 'c01',
    countryName: 'India',
    RegEx: {},
    minLength: 5,
    maxLength: 10,
    countryCode: '+91',
    numberFormat: '9123456789',
  },
  {
    countryId: 'c39',
    countryName: 'Indonesia',
    RegEx: {},
    minLength: 9,
    maxLength: 9,
    countryCode: '+62',
    numberFormat: '812345678',
  },
  {
    countryId: 'c50',
    countryName: 'Iran',
    RegEx: {},
    minLength: 10,
    maxLength: 10,
    countryCode: '+98',
    numberFormat: '9123456789',
  },
  {
    countryId: 'c51',
    countryName: 'Iraq',
    RegEx: {},
    minLength: 10,
    maxLength: 10,
    countryCode: '+964',
    numberFormat: '7912345678',
  },
  {
    countryId: 'c38',
    countryName: 'Israel',
    RegEx: {},
    minLength: 9,
    maxLength: 9,
    countryCode: '+972',
    numberFormat: '501234567',
  },
  {
    countryId: 'c12',
    countryName: 'Italy',
    RegEx: {},
    minLength: 9,
    maxLength: 11,
    countryCode: '+39',
    numberFormat: '3123456789',
  },
  {
    countryId: 'c20',
    countryName: 'Japan',
    RegEx: {},
    minLength: 10,
    maxLength: 11,
    countryCode: '+81',
    numberFormat: '09012345678',
  },
  {
    countryId: 'c72',
    countryName: 'Kazakhstan',
    RegEx: {},
    minLength: 10,
    maxLength: 10,
    countryCode: '+997',
    numberFormat: '902345678',
  },
  {
    countryId: 'c14',
    countryName: 'Korea',
    RegEx: {},
    minLength: 10,
    maxLength: 10,
    countryCode: '+82',
    numberFormat: '12345678',
  },
  {
    countryId: 'c52',
    countryName: 'Kuwait',
    RegEx: {},
    minLength: 8,
    maxLength: 8,
    countryCode: '+965',
    numberFormat: '50012345',
  },
  {
    countryId: 'c66',
    countryName: 'Laos',
    RegEx: {},
    minLength: 10,
    maxLength: 10,
    countryCode: '+856',
    numberFormat: '2023123456',
  },
  {
    countryId: 'c06',
    countryName: 'Lebanon',
    RegEx: {},
    minLength: 7,
    maxLength: 8,
    countryCode: '+961',
    numberFormat: '71123456',
  },
  {
    countryId: 'c70',
    countryName: 'Libya',
    RegEx: {},
    minLength: 10,
    maxLength: 10,
    countryCode: '+218',
    numberFormat: '702345678',
  },
  {
    countryId: 'c63',
    countryName: 'Macau',
    RegEx: {},
    minLength: 8,
    maxLength: 8,
    countryCode: '+853',
    numberFormat: '66123456',
  },
  {
    countryId: 'c32',
    countryName: 'Malaysia',
    RegEx: {},
    minLength: 9,
    maxLength: 9,
    countryCode: '+60',
    numberFormat: '123456789',
  },
  {
    countryId: 'c58',
    countryName: 'Martinique',
    RegEx: {},
    minLength: 10,
    maxLength: 10,
    countryCode: '+596',
    numberFormat: '696201234',
  },
  {
    countryId: 'c43',
    countryName: 'Mexico',
    RegEx: {},
    minLength: 11,
    maxLength: 11,
    countryCode: '+52',
    numberFormat: '12221234567',
  },
  {
    countryId: 'c62',
    countryName: 'Mongolia',
    RegEx: {},
    minLength: 8,
    maxLength: 8,
    countryCode: '+976',
    numberFormat: '88123456',
  },
  {
    countryId: 'c18',
    countryName: 'Morocco',
    RegEx: {},
    minLength: 9,
    maxLength: 9,
    countryCode: '+212',
    numberFormat: '650123456',
  },
  {
    countryId: 'c11',
    countryName: 'Myanmar',
    RegEx: {},
    minLength: 10,
    maxLength: 12,
    countryCode: '+95',
    numberFormat: '092123456',
  },
  {
    countryId: 'c21',
    countryName: 'Nicaragua',
    RegEx: {},
    minLength: 8,
    maxLength: 8,
    countryCode: '+505',
    numberFormat: '81234567',
  },
  {
    countryId: 'c59',
    countryName: 'Oman',
    RegEx: {},
    minLength: 8,
    maxLength: 8,
    countryCode: '+968',
    numberFormat: '92123456',
  },
  {
    countryId: 'c07',
    countryName: 'Panama',
    RegEx: {},
    minLength: 10,
    maxLength: 12,
    countryCode: '+507',
    numberFormat: '60012345',
  },
  {
    countryId: 'c64',
    countryName: 'Paraguay',
    RegEx: {},
    minLength: 9,
    maxLength: 9,
    countryCode: '+595',
    numberFormat: '961456789',
  },
  {
    countryId: 'c05',
    countryName: 'Peru',
    RegEx: {},
    minLength: 8,
    maxLength: 9,
    countryCode: '+51',
    numberFormat: '912345678',
  },
  {
    countryId: 'c53',
    countryName: 'Philippines',
    RegEx: {},
    minLength: 10,
    maxLength: 10,
    countryCode: '+63',
    numberFormat: '9051234567',
  },
  {
    countryId: 'c09',
    countryName: 'Portugal',
    RegEx: {},
    minLength: 10,
    maxLength: 12,
    countryCode: '+351',
    numberFormat: '912345678',
  },
  {
    countryId: 'c60',
    countryName: 'Qatar',
    RegEx: {},
    minLength: 8,
    maxLength: 8,
    countryCode: '+974',
    numberFormat: '33123456',
  },
  {
    countryId: 'c28',
    countryName: 'Russian Federation',
    RegEx: {},
    minLength: 10,
    maxLength: 10,
    countryCode: '+7',
    numberFormat: '9123456789',
  },
  {
    countryId: 'c42',
    countryName: 'Saudi Arabia',
    RegEx: {},
    minLength: 9,
    maxLength: 9,
    countryCode: '+966',
    numberFormat: '512345678',
  },
  {
    countryId: 'c27',
    countryName: 'Singapore',
    RegEx: {},
    minLength: 8,
    maxLength: 8,
    countryCode: '+65',
    numberFormat: '81234567',
  },
  {
    countryId: 'c22',
    countryName: 'Spain',
    RegEx: {},
    minLength: 9,
    maxLength: 9,
    countryCode: '+34',
    numberFormat: '612345678',
  },
  {
    countryId: 'c15',
    countryName: 'Switzerland',
    RegEx: {},
    minLength: 10,
    maxLength: 12,
    countryCode: '+41',
    numberFormat: '0781234567',
  },
  {
    countryId: 'c37',
    countryName: 'Taiwan',
    RegEx: {},
    minLength: 9,
    maxLength: 9,
    countryCode: '+886',
    numberFormat: '912345678',
  },
  {
    countryId: 'c08',
    countryName: 'Thailand',
    RegEx: {},
    minLength: 10,
    maxLength: 12,
    countryCode: '+66',
    numberFormat: '0812345678',
  },
  {
    countryId: 'c34',
    countryName: 'Tunisia',
    RegEx: {},
    minLength: 8,
    maxLength: 8,
    countryCode: '+216',
    numberFormat: '20123456',
  },
  {
    countryId: 'c25',
    countryName: 'Turkey',
    RegEx: {},
    minLength: 10,
    maxLength: 10,
    countryCode: '+90',
    numberFormat: '5012345678',
  },
  {
    countryId: 'c26',
    countryName: 'UAE',
    RegEx: {},
    minLength: 9,
    maxLength: 9,
    countryCode: '+971',
    numberFormat: '501234567',
  },
  {
    countryId: 'c10',
    countryName: 'USA',
    RegEx: {},
    minLength: 10,
    maxLength: 10,
    countryCode: '+1',
    numberFormat: '2012345678',
  },
  {
    countryId: 'c68',
    countryName: 'Uruguay',
    RegEx: {},
    minLength: 10,
    maxLength: 10,
    countryCode: '+598',
    numberFormat: '722345678',
  },
  {
    countryId: 'c29',
    countryName: 'Venezuela',
    RegEx: {},
    minLength: 10,
    maxLength: 10,
    countryCode: '+58',
    numberFormat: '4121234567',
  },
  {
    countryId: 'c04',
    countryName: 'Vietnam',
    RegEx: {},
    minLength: 10,
    maxLength: 12,
    countryCode: '+84',
    numberFormat: '912345678',
  },
]
