import { memo, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { DEFAULT_VALUE } from "../../constants/data";
import { Box, TextareaAutosize, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import StyledBannerContainer from "./StyledBannerContainer";

const calculateRows = (message) => {
  const row = message || "";
  const rows = row.split("\n").length;
  const pairsCount = Math.floor(row.length / 30);
  const remainingCharacters = row.length % 30;
  const totalLineCount = pairsCount + (remainingCharacters > 0 ? 1 : 0);
  return rows + totalLineCount - 1;
};

const Banner = ({
  preview,
  right,
  bannerMessage,
  updateInputChanges,
  updateWebsiteDetails,
  loading,
}) => {
  const bannerMessageRef = useRef("");
  const [fontSize, setFontSize] = useState("48px");
  const [isFocused, setIsFocused] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    const totalRows = calculateRows(bannerMessage);

    const newSize =
      totalRows > 4
        ? isMobile
          ? theme.typography.h5.fontSize
          : theme.typography.h2.fontSize
        : isMobile
        ? theme.typography.h4.fontSize
        : theme.typography.h1.fontSize;
    setFontSize(newSize);
  }, [bannerMessage, isMobile, theme.typography]);

  const handleFocus = (event) => {
    setIsFocused(true);
    event.target.select();
  };

  const handleBlur = (event) => {
    setIsFocused(false);
    updateWebsiteDetails(event);
  };

  return (
    <Box
      sx={{
        order: right ? 2 : "unset",
        marginRight: right ? theme.spacing(2) : "unset",
        marginLeft: right ? "unset" : theme.spacing(2),
        minHeight: theme.spacing(25)
      }}
    >
      <Box
        sx={{
          width: "100%",
          textAlign: right ? "right" : "left",
          color: theme.palette.common.white,
        }}
      >
        <StyledBannerContainer>
          {preview ? (
            <TextareaAutosize
              name={DEFAULT_VALUE.bannerMessage.name}
              ref={bannerMessageRef}
              maxLength={DEFAULT_VALUE.bannerMessage.maxLength}
              defaultValue={bannerMessage}
              minRows={2}
              maxRows={6}
              style={{
                fontSize: fontSize,
                background: "transparent",
                color: theme.palette.common.white,
                padding: `${theme.spacing(1.5)} ${theme.spacing(0)}`,
                outline: "none",
                width: "100%",
                textAlign: right ? "right" : "left",
                resize: "none",
                fontFamily: "Noto Sans, sans-serif",
                fontWeight: "800",
              }}
              value={bannerMessage}
              onChange={updateInputChanges}
              inputProps={{ id: DEFAULT_VALUE.bannerMessage.id }}
              id={DEFAULT_VALUE.bannerMessage.id}
              onFocus={handleFocus}
              onBlur={handleBlur}
              disabled={loading}
            />
          ) : (
            <Typography
              variant="h3"
              sx={{
                fontSize: fontSize,
                whiteSpace: "pre-wrap",
                wordBreak: "break-word",
                color: theme.palette.common.white,
                width: "100%",
                height: "auto",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                [theme.breakpoints.between(768, 900)]: {
                  height: theme.spacing(37),
                  paddingTop: theme.spacing(5),
                },
              }}
            >
              {bannerMessage}
            </Typography>
          )}
        </StyledBannerContainer>
      </Box>
      {preview && isFocused && (
        <Box>
          <Typography
            sx={{
              textAlign: right ? "right" : "left",
              color: theme.palette.common.white,
            }}
          >
            {bannerMessage.length} / {DEFAULT_VALUE.bannerMessage.maxLength}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

Banner.propTypes = {
  preview: PropTypes.bool,
  right: PropTypes.bool.isRequired,
  bannerMessage: PropTypes.string,
  updateInputChanges: PropTypes.func.isRequired,
  updateWebsiteDetails: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

Banner.defaultProps = {
  preview: false,
  bannerMessage: "",
};

export default memo(Banner);
