import { memo, useState } from "react";
import PoliciesHandlerComponent from "../Footer/PoliciesHandlerComponent/PoliciesHandlerComponent";
import { Box, Typography, Link } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const Policy = () => {
  const currentYear = new Date().getFullYear();
  const [content, setContent] = useState(null);
  const theme = useTheme();

  const closeContentHandler = () => {
    setContent(null);
  };

  return (
    <>
      <Box
        sx={{
          height: theme.spacing(15),
          color: theme.palette.common.black,
          display: "flex",
          alignItems: "center",
        }}
        data-testid="policy"
      >
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: { xs: "center", sm: "left" },
            alignItems: "center",
            textAlign: "center",
            whiteSpace: { xs: "normal", sm: "nowrap" },
            marginLeft: theme.spacing(18),
            [theme.breakpoints.down(767)]: {
              marginLeft: theme.spacing(2),
            },
          }}
          data-testid="policy-message"
        >
          <Typography
            variant="body1"
            sx={{
              color: "text.primary",
              fontSize: 16,
              fontFamily: "Noto Sans, sans-serif",
            }}
          >
            © {currentYear} WSE Hong Kong Limited
          </Typography>
          <Typography variant="body1" sx={{ mx: 1 }}>
            |
          </Typography>
          <Link
            component="button"
            variant="body1"
            onClick={() => setContent("privacy")}
            sx={{
              color: theme.palette.info.main,
              textDecoration: "underline",
              cursor: "pointer",
            }}
          >
            Privacy Policy
          </Link>
          <Typography variant="body1" sx={{ mx: 1 }}>
            |
          </Typography>
          <Link
            component="button"
            variant="body1"
            onClick={() => setContent("cookie")}
            sx={{
              color: theme.palette.info.main,
              textDecoration: "underline",
              cursor: "pointer",
            }}
          >
            Cookie Policy
          </Link>
          <Typography variant="body1" sx={{ mx: 1 }}>
            |
          </Typography>
          <Link
            component="button"
            variant="body1"
            onClick={() => setContent("terms-of-use")}
            sx={{
              color: theme.palette.info.main,
              textDecoration: "underline",
              cursor: "pointer",
            }}
          >
            Terms Of Use
          </Link>
        </Box>
      </Box>
      {content && (
        <PoliciesHandlerComponent
          content={content}
          closeContentHandler={closeContentHandler}
        />
      )}
    </>
  );
};

export default memo(Policy);
