import { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Banner,
  LoginForm,
  CornerLoader,
  SnackbarAlert,
  PreviewFooter,
  Grid,
} from "../../components";
import { fetchSiteDetails, loginProspect } from "../../store/appStore";
import { DEFAULT_VALUE } from "../../constants/data";
import { camelCaseToReadable, validateEmail } from "../../utils/common.service";
import Header from "../../components/Header/Header";
import GridContainer from "../../components/GridContainer/GridContainer";

const ProspectRegistration = () => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.appKey.loading);
  const isPhoneFieldEnabled = useSelector(
    (state) => state.appKey.isPhoneFieldEnabled
  );
  const isPhoneFieldMandatoryForProspects = useSelector(
    (state) => state.appKey.isPhoneFieldMandatoryForProspects
  );
  const companyId = useSelector((state) => state.appKey.companyId);
  const centerReferenceId = useSelector(
    (state) => state.appKey.centerReferenceId
  );
  const showTestResult = useSelector((state) => state.appKey.showTestResult);
  const placeHolders = useSelector((state) => state.appKey.placeHolder);
  const isRightToLeftLanguageLayout = useSelector(
    (state) => state.appKey.isRightToLeftLanguageLayout
  );
  const logo = useSelector((state) => state.appKey.logo);
  const defaultCurrency = useSelector((state) => state.appKey.defaultCurrency);
  const error = useSelector((state) => state.appKey.error);
  const requiredFields = useMemo(() => {
    const requiredField = DEFAULT_VALUE.requiredFields;
    if (isPhoneFieldMandatoryForProspects && isPhoneFieldEnabled) {
      requiredField.push("phoneNumber");
    }
    if (placeHolders.FieldOneStatus === 1) {
      requiredField.push("fieldOneText");
    }
    if (placeHolders.FieldTwoStatus === 1) {
      requiredField.push("fieldTwoText");
    }
    if (placeHolders.FieldThreeStatus === 1) {
      requiredField.push("fieldThreeText");
    }
    if (placeHolders.FieldFourStatus === 1) {
      requiredField.push("fieldFourText");
    }
    return requiredField;
  }, [isPhoneFieldMandatoryForProspects, isPhoneFieldEnabled]);

  const disableFormButton = () => {
    return (
      requiredFields.some((e) => errorData[e]) ||
      requiredFields.some((e) => !formData[e])
    );
  };

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    countryCode: "",
    phoneNumber: "",
    fieldOneText: null,
    fieldTwoText: null,
    fieldThreeText: null,
    fieldFourText: null,
  });

  const [errorData, setErrorData] = useState({
    firstName: null,
    lastName: null,
    email: null,
    phoneNumber: null,
    fieldOneText: null,
    fieldTwoText: null,
    fieldThreeText: null,
    fieldFourText: null,
  });

  useEffect(() => {
    dispatch(fetchSiteDetails());
  }, []);

  const updateInputChanges = (event, value = null) => {
    const key = event.target.id;
    const countryCode = value?.countryCode;
    value =
      key === "phoneNumber"
        ? event.target.value.replace(/[^0-9]/g, "")
        : event.target.value;

    setErrorData((prevErrorData) => {
      const updatedErrors = { ...prevErrorData };

      if (requiredFields.includes(key) && value) {
        if (key === requiredFields[2] && !validateEmail(value)) {
          updatedErrors[key] = `Invalid ${camelCaseToReadable(key)}`;
        } else {
          updatedErrors[key] = null;
        }
      }

      return updatedErrors;
    });

    if (countryCode) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        countryCode,
      }));
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [key]: value,
      }));
    }
  };

  const updateWebsiteDetails = (e) => {
    console.log("Called onblure");
    const key = e.target.id;
    const value = e.target.value;
    setErrorData((prevErrorData) => {
      const updatedErrors = { ...prevErrorData };

      if (requiredFields.includes(key)) {
        if (!value) {
          updatedErrors[key] = `${camelCaseToReadable(key)} is required`;
        } else if (key === requiredFields[2] && !validateEmail(value)) {
          updatedErrors[key] = `Invalid ${camelCaseToReadable(key)}`;
        } else {
          updatedErrors[key] = null;
        }
      }
      return updatedErrors;
    });
  };

  const createProspect = () => {
    const body = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      email: formData.email,
      password: "Pass@123",
      companyId: companyId,
      phoneNumber: formData.phoneNumber
        ? formData.countryCode + " " + formData.phoneNumber
        : null,
      showTestResult: showTestResult,
      trial: null,
      centerReferenceId: centerReferenceId,
      prospectAgnosticParameters: {
        ...(formData.fieldOneText !== null && {
          fieldOne: formData.fieldOneText,
        }),
        ...(formData.fieldTwoText !== null && {
          fieldTwo: formData.fieldTwoText,
        }),
        ...(formData.fieldThreeText !== null && {
          fieldThree: formData.fieldThreeText,
        }),
        ...(formData.fieldFourText !== null && {
          fieldFour: formData.fieldFourText,
        }),
      },
    };
    dispatch(loginProspect(body));
  };

  return (
    <>
      {error && <SnackbarAlert message={error} />}
      {loading && <CornerLoader />}
      <Grid container>
        <Grid xs={12}>
          <Header preview={false} />
        </Grid>
        <GridContainer
          layoutDirection={isRightToLeftLanguageLayout}
        >
          <Grid
            sm={12}
            md={6}
            lg={7}
            justifyContent={{ xs: "center", md: "end" }}
            sx={{ display: "flex", alignItems: "center" }}
          >
            <Banner
              right={isRightToLeftLanguageLayout}
              bannerMessage={placeHolders.BannerMessage}
              updateInputChanges={updateInputChanges}
              updateWebsiteDetails={updateWebsiteDetails}
              loading={loading}
            />
          </Grid>
          <Grid
            md={6}
            lg={5}
            display={{ xs: "none", md: "flex" }}
            sx={{ justifyContent: "start", alignItems: "center" }}
          >
            <LoginForm
              right={isRightToLeftLanguageLayout}
              isPhoneFieldEnabled={isPhoneFieldEnabled}
              companyWebsiteModel={formData}
              updateWebsiteDetails={updateWebsiteDetails}
              updateInputChanges={updateInputChanges}
              loading={loading}
              logo={logo}
              placeholderData={placeHolders}
              errorData={errorData}
              length={DEFAULT_VALUE.length}
              disabled={disableFormButton()}
              defaultCurrency={defaultCurrency}
              // TODO:- Need to enable this once API dependency completed
              onSubmit={createProspect}
            />
          </Grid>
        </GridContainer>
        <Grid
          container
          xs={12}
          display={{ xs: "flex", md: "none" }}
          sx={{ justifyContent: "center", alignItems: "center" }}
        >
          <LoginForm
            right={isRightToLeftLanguageLayout}
            isPhoneFieldEnabled={isPhoneFieldEnabled}
            companyWebsiteModel={formData}
            updateWebsiteDetails={updateWebsiteDetails}
            updateInputChanges={updateInputChanges}
            loading={loading}
            logo={logo}
            placeholderData={placeHolders}
            errorData={errorData}
            length={DEFAULT_VALUE.length}
            disabled={disableFormButton()}
            defaultCurrency={defaultCurrency}
            // TODO:- Need to enable this once API dependency completed
            onSubmit={createProspect}
          />
        </Grid>
        <Grid xs={12}>
          <PreviewFooter />
        </Grid>
      </Grid>
    </>
  );
};

export default ProspectRegistration;
