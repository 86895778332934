import { useState, forwardRef, memo } from 'react';
import PropTypes from 'prop-types';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Alert = forwardRef(function Alert(props, ref) {
  return (
    <MuiAlert
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      ref={ref}
      variant="filled"
      {...props}
    />
  );
});

const SnackbarAlert = ({ message }) => {
  const [open, setOpen] = useState(true);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  return (
      <Snackbar open={open} autoHideDuration={5000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          onClose={handleClose}
          severity="error"
        >
          {message}
        </Alert>
      </Snackbar>
  );
}

SnackbarAlert.propTypes = {
  message: PropTypes.string.isRequired,
};

export default memo(SnackbarAlert);
