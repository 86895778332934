import axios from 'axios';
import { postApiWithoutAuth } from './apiRequest';

const axiosInstance = axios.create({
	headers: {
		'x-territory-key': process.env.REACT_APP_TERRITORY_KEY,
	},
	baseURL: process.env.REACT_APP_LS_API_URL,
});

axiosInstance.interceptors.response.use((res) => res.data);

export const createProspect = async (data) => {
  return axiosInstance.post('/internal', data, {
		baseURL: process.env.REACT_APP_PROSPECT_API_URL,
	});
};

export const getIdamToken = async (email) => {
  const formData = new FormData();
  formData.append('username', 'b2b_' + email);
  formData.append('password', 'Pass@123');
  formData.append('clientId', process.env.REACT_APP_CLIENT_ID);
  formData.append('clientSecret', process.env.REACT_APP_CLIENT_SECRET);

  const getTokenUrl = process.env.REACT_APP_IDAM_TOKEN_API_URL;
  return postApiWithoutAuth(getTokenUrl, formData);
};

