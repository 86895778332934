import { getToken } from '../localStorage/localStorage';
import axios from 'axios';

/**
 * To call rest api with default header
 * @param {string} type
 * @param {string} url
 * @param {...object} rest
 */
 export const apiRequestWithDefaultHeader = (type, url, rest) => {
  return fetch(url, {
    method: type,
    ...rest
  });
};

const axiosInstance = axios.create({
  headers: {
    Authorization: `Bearer ${getToken()}`,
  },
  baseURL: process.env.REACT_APP_CONTRACT_API_URL,
});

axiosInstance.interceptors.response.use((res) => res.data, (err) => {
  if (err?.response?.status === 401) {
    window.location.replace(`${process.env.REACT_APP_NSE_URL}/login`)
  }
  throw err;
});

export const getApi = (url) => {
  return axiosInstance.get(url);
}

export const postApi = (url, data) => {
  return axiosInstance.post(url, data);
}

export const postApiWithoutAuth = (url, data, headers) =>{
  return axios.post(url, data, {headers })
}
