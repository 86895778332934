import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import landingPageImage from "../../assets/images/landing-page.png";

const GridContainer = ({ children, layoutDirection }) => {
  return (
    <Grid
      container
      xs={12}
      minHeight={{ sm: "calc(100vh - 120px)", md: "calc(100vh - 60px)" }}
      sx={{
        background: `url(${landingPageImage}) center center/cover no-repeat`,
        boxShadow: "inset 2000px 0 0 0 rgba(23, 25, 35, 0.56)",
        direction: layoutDirection ? "rtl" : "ltr",
      }}
    >
      {children}
    </Grid>
  );
};

GridContainer.propTypes = {
  children: PropTypes.node.isRequired,
  isRightToLeftLanguageLayout: PropTypes.bool.isRequired,
};

export default GridContainer;
