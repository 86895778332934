
import ProspectRegistrationPreview from '../page/ProspectRegistrationPreview/ProspectRegistrationPreview';
import ProspectRegistration from '../page/ProspectRegistration/ProspectRegistration';

export const routesConfig = [
  {
    path: '/',
    element: <ProspectRegistration />
  },
  {
    path: '/selfregistration/preview/:companyId',
    element: <ProspectRegistrationPreview preview={true} />,
  },
]
