import { configureStore } from '@reduxjs/toolkit';
import appReducer, { APP_KEY } from './appStore';

export default configureStore({
  reducer: {
    [APP_KEY]: appReducer,
  }
});

export const setupStore = (preloadedState) => configureStore({
  reducer: {
    [APP_KEY]: appReducer,
  },
  preloadedState,
});
