export const getToken = () => {
    try {
        const user = localStorage.getItem('wse.user')
        if (user) {
            const decode = JSON.parse(user);
            return decode?.accessToken || '';
        }
        return '';
    } catch (e) {
        return '';
    }
}