export const addAsteriskValue = (webSiteDetails, requiredFields) => {
    return Object.keys(webSiteDetails).reduce((res, key) => {
      if (requiredFields.includes(key)) {
        res[key] = `${webSiteDetails[key]}${webSiteDetails[key].at(-1) === '*' ? '' : '*'}`;
      } else {
        res[key] = webSiteDetails[key];
      }
      return res;
    }, {});
}

export const removeAsterisksIfEndsWithAsterisk = (value) => { 
  return value?.at(-1) === '*' ? value.replace(/\*/g, '') : value;
};

export const appendAsteriskIfStatusIsMandatory = (fieldStatus, fieldText) => {
  return fieldStatus === 1 ? `${fieldText}*` : fieldText;
}

export const getSubDomain = () => {
  const hostname = window.location.hostname;
  const hostnameParts = hostname.split('.');

  if (hostnameParts.length >= 2) {
      const subdomain = hostnameParts[0];
      return subdomain;
  }
  return null;
}

export const camelCaseToReadable = (string) => {
  return string
    .replace(/([a-z])([A-Z])/g, '$1 $2')
    .replace(/([A-Z])([A-Z][a-z])/g, '$1 $2')
    .replace(/^./, (str) => str.toUpperCase());
}

export const validateEmail = (input) => {
  const emailPattern = /^[a-z0-9][-a-z0-9.+_]+@([-a-z0-9]+\.)+[a-z]{2,5}$/i;
  return emailPattern.test(input);
}
