import { createBrowserRouter, RouterProvider } from "react-router-dom";
import {
  ThemeProvider,
  SettingsProvider,
  LocalizationProvider,
} from "hello-design-system";

import { routesConfig } from "./utils/routes";

const router = createBrowserRouter(routesConfig);

const App = () => (
  <SettingsProvider
    defaultSettings={{
      themeMode: "light",
      themeDirection: "ltr",
      themeContrast: "default",
      themeLayout: "vertical",
      themeColorPresets: "default",
      themeStretch: false,
    }}
  >
    <ThemeProvider>
      <LocalizationProvider>
        <RouterProvider router={router} />
      </LocalizationProvider>
    </ThemeProvider>
  </SettingsProvider>
);

export default App;
